
import { Component, Mixins } from 'vue-property-decorator';

import JobsRatio from '@/components/data-operations/common/status/jobs-ratio.vue';

import HeaderInfosMixin from '../header-infos';
import StatusCollectionMixin from '@/mixins/data-operations/collection/status-collection-mixin';
import { WORKFLOW_STATUS_ITEM } from '@/constants/router/routes-names';
import {
	ACTIONS,
	ID,
	JOBS,
	LAST_FIRE_DATE_FROM_NOW,
	LAST_UPDATE_FROM_NOW,
	TRIGGERED_JOBS,
} from '@/constants/data-operations/listing/header-items';
import { workflowStatus } from '@/store/modules/easy-firestore/workflow-status';
import { workflowStatusLight } from '@/store/modules/easy-firestore/workflow-status-light';

@Component({
	components: { JobsRatio },
})
export default class WorkflowStatusListingView extends Mixins(HeaderInfosMixin, StatusCollectionMixin) {
	moduleName: string = workflowStatus.moduleName;
	moduleNameLight: string = workflowStatusLight.moduleName;
	overriddenColumns: string[] = [
		'id',
		'jobs',
		'triggered_jobs',
		'last_update_date_from_now',
		'last_fire_date_from_now',
	];

	get routeName() {
		return WORKFLOW_STATUS_ITEM;
	}

	get headers() {
		return [ID, JOBS, TRIGGERED_JOBS, LAST_UPDATE_FROM_NOW, LAST_FIRE_DATE_FROM_NOW, ACTIONS];
	}
}
