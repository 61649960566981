var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("data-management-header", {
        attrs: { workflowName: _vm.workflowName, tabsItems: _vm.tabsItems },
      }),
      _c("listing-component", {
        attrs: {
          type: _vm.listingType,
          "module-name": _vm.moduleName,
          headers: _vm.headers,
          "overridden-columns": _vm.overriddenColumns,
        },
        scopedSlots: _vm._u([
          {
            key: "id",
            fn: function ({ item: { id } }) {
              return [
                _c(
                  "router-link",
                  { attrs: { to: { name: _vm.routeName, params: { id } } } },
                  [
                    _c("span", { staticClass: "font-weight-medium" }, [
                      _vm._v(_vm._s(id)),
                    ]),
                  ]
                ),
              ]
            },
          },
          {
            key: "jobs",
            fn: function ({ item: { jobs } }) {
              return [
                jobs
                  ? _c("span", [_vm._v(_vm._s(Object.keys(jobs).length))])
                  : _vm._e(),
              ]
            },
          },
          {
            key: "triggered_jobs",
            fn: function ({ item: { jobs } }) {
              return [
                jobs ? _c("jobs-ratio", { attrs: { jobs: jobs } }) : _vm._e(),
              ]
            },
          },
          {
            key: "last_update_date_from_now",
            fn: function ({ item: { last_modified } }) {
              return [
                _vm._v(
                  " " +
                    _vm._s(_vm._f("moment")(last_modified, "from", "now")) +
                    " "
                ),
              ]
            },
          },
          {
            key: "last_fire_date_from_now",
            fn: function ({ item: { target_dag_last_executed } }) {
              return [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm._f("moment")(target_dag_last_executed, "from", "now")
                    ) +
                    " "
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }